const options = {
  rootMargin: '0px 0px -0px 0px',
  threshold: 0.5,
};

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('is-visible');
    } else {
      entry.target.classList.remove('is-visible');
    }
  });
}, options);

const contentSections = document.querySelectorAll('.elementor-element');
contentSections.forEach((section) => {
  observer.observe(section);
});

window.addEventListener(
  'DOMContentLoaded',
  initializeElementorParallaxElements
);

window.addEventListener('scroll', onScroll);
window.addEventListener('beforeunload', () => {
  window.removeEventListener('scroll', onScroll);
});

function initializeElementorParallaxElements() {
  const elementorParallaxElements = document.querySelectorAll('.parallax');
  elementorParallaxElements.forEach((el) => {
    const randomSpeed = Math.random() * (0.15 - 0.05) + 0.05;
    const randomOrientation = Math.random() < 0.5 ? 'up' : 'down';
    el.dataset.speed = randomSpeed;
    el.dataset.orientation = randomOrientation;
  });
  onScroll();
}

function onScroll() {
  const scrollTop = window.pageYOffset;
  const parallaxElements = document.querySelectorAll('.parallax');
  parallaxElements.forEach((el) => {
    const rect = el.getBoundingClientRect();
    if (rect.top < window.innerHeight && rect.bottom > 0) {
      const speed = el.dataset.speed ? parseFloat(el.dataset.speed) : 0.1;
      const orientation = el.dataset.orientation || 'down';
      const y = (rect.top - window.innerHeight / 2) * speed;
      el.style.transform = `translateY(${orientation === 'up' ? -y : y}px)`;
    }
  });
}
